import { DashboardLayout } from 'components/Layout'
import { useCurrentPath } from 'hooks'
import { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { anyRoute } from './LandingRoutes'
import TestComponent from 'components/shared/TestComponent'

// import TestTable from 'components/Dashboard/pages/TestPage/TestTable/TestTable'

const Calendar = lazy(() => import('components/Dashboard/pages/Calendar'))
const CreateSquad = lazy(() => import('components/Dashboard/pages/CreateSquad'))
const SquadCreateForm = lazy(() => import('components/Dashboard/pages/CreateSquad/SquadCreateForm'))
const Dashboard = lazy(() => import('components/Dashboard/pages/Dashboard'))
const EmailVerification = lazy(() => import('components/Dashboard/pages/EmailVerification'))
// const Home = lazy(() => import('components/Dashboard/pages/Home'))
const Notifications = lazy(() => import('components/Dashboard/pages/Notifications')) // TODO: remove index
const OnBoarding = lazy(() => import('components/Dashboard/pages/OnBoarding'))
const Search = lazy(() => import('components/Dashboard/pages/Search'))
const Squad = lazy(() => import('components/Dashboard/pages/Squad'))
const Billing = lazy(() => import('components/Dashboard/pages/Squad/Billing'))
const CreateMatch = lazy(() => import('components/Dashboard/pages/Squad/CreateMatch'))
const EditAdmins = lazy(() => import('components/Dashboard/pages/Squad/EditAdmins'))
const SquadLogo = lazy(() => import('components/Dashboard/pages/Squad/EditSquad/SquadLogo'))
const SquadShirts = lazy(() => import('components/Dashboard/pages/Squad/EditSquad/SquadShirts'))
const SquadDetails = lazy(() => import('components/Dashboard/pages/Squad/EditSquad/SquadDetails'))
const EditStaff = lazy(() => import('components/Dashboard/pages/Squad/EditStaff'))
const ManageSubscription = lazy(() => import('components/Dashboard/pages/Squad/ManageSubscription/ManageSubscription'))
const Upgrade = lazy(() => import('components/Dashboard/pages/Squad/MembershipPayment/Upgrade'))
const MembershipSettings = lazy(() => import('components/Dashboard/pages/Squad/MembershipSettings'))
// const SVGFormation = lazy(() => import('components/Dashboard/pages/TestPage/SVGFormation'))
// const SVGFormationHorizontal = lazy(() => import('components/Dashboard/pages/TestPage/SVGFormationHorizontal'))
// const UploadVideo = lazy(() => import('components/Dashboard/pages/TestPage/UploadVideo'))
const SquadSettings = lazy(() => import('components/Dashboard/pages/Squad/EditSquad/SquadSettings'))
const User = lazy(() => import('components/Dashboard/pages/User'))
const MySquads = lazy(() => import('components/Dashboard/pages/User/MySquads'))
const UserPage = lazy(() => import('components/Dashboard/pages/UserPage'))
const UserProfile = lazy(() => import('components/Dashboard/pages/UserProfile'))
const UserPhoto = lazy(() => import('components/Dashboard/pages/UserProfile/UserPhoto'))
const UserPositions = lazy(() => import('components/Dashboard/pages/UserProfile/UserPositions'))
const UserAttributes = lazy(() => import('components/Dashboard/pages/UserProfile/UserAttributes'))
// const TestTable = lazy(() => import('components/Dashboard/pages/TestPage/TestTable/TestTable'))
const UploadVideo = lazy(() => import('components/Dashboard/pages/TestPage/UploadVideo'))
const TestShirts = lazy(() => import('components/shared/TestComponent'))

export const protectedRoutes = [
    {
        path: '/dashboard',
        element: UserPage,
    },
    {
        path: '/dashboard/inbox',
        element: Dashboard,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/squad-details',
        element: SquadDetails,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/squad-logo',
        element: SquadLogo,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/squad-shirts',
        element: SquadShirts,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/backroom-staff',
        element: EditStaff,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/administrators',
        element: EditAdmins,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/membership-settings',
        element: MembershipSettings,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/upgrade',
        element: Upgrade,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/manage-subscription',
        element: ManageSubscription,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/billing',
        element: Billing,
    },
    {
        path: '/dashboard/squads/:squadSlug/admin/squad-settings',
        element: SquadSettings,
    },
    {
        path: '/dashboard/squads/:squadSlug/:squadTab?/*',
        element: Squad,
    },
    {
        path: '/dashboard/profile/settings',
        element: UserProfile,
    },
    {
        path: '/dashboard/profile/photo',
        element: UserPhoto,
    },
    {
        path: '/dashboard/profile/attributes',
        element: UserAttributes,
    },
    {
        path: '/dashboard/profile/positions',
        element: UserPositions,
    },
    {
        path: '/dashboard/squad-plans',
        element: CreateSquad,
    },
    {
        path: '/dashboard/create-squad/:type',
        element: SquadCreateForm,
    },
    {
        path: '/dashboard/search/:searchType?',
        element: Search,
    },
    {
        path: '/dashboard/notifications',
        element: Notifications,
    },
    {
        path: '/dashboard/person/:username/*',
        element: User,
    },
    {
        path: '/dashboard/squads/:squadSlug/player/:username/*',
        element: User,
    },
    {
        path: '/dashboard/squads/:squadSlug/create-match',
        element: CreateMatch,
    },
    {
        path: '/dashboard/onboard',
        element: OnBoarding,
    },
    {
        path: '/dashboard/verify-email',
        element: EmailVerification,
    },
    {
        path: '/dashboard/my-squads',
        element: MySquads,
    },
    // {
    //     path: '/dashboard/calendar',
    //     element: Calendar,
    // },
    {
        path: '/dashboard/user',
        element: UserPage,
    },
    // FORMATION DISPLAY TEST
    // {
    //     path: '/dashboard/formation-display',
    //     element: FormationPicker rotated displayOnly lineup={DISPLAY_TEST_DATA},
    // },
    // {
    //     path: '/dashboard/person/:username/info',
    //     element: User,
    // },
    // {
    //     path: '/dashboard/test-table',
    //     element: TestTable,
    // },
    {
        path: '/dashboard/test',
        element: TestShirts,
    },
    // {
    //     path: '/dashboard/svg-formation',
    //     element: SVGFormationHorizontal,
    // },
]
export const protectedRoutesPath = protectedRoutes.map(route => route.path)

const PrivateRoutes = () => {
    const { user, masterData } = useSelector(state => state)
    const currentPath = useCurrentPath([...protectedRoutes, ...anyRoute])
    const isAnyRoute = anyRoute.map(r => r.path).includes(currentPath)
    const isPrivateRoute = protectedRoutesPath.includes(currentPath)

    if (!isPrivateRoute || !user || isAnyRoute) return null

    if (!user || !masterData.countries) return null

    return (
        <DashboardLayout>
            <Suspense fallback={<div />}>
                <Routes>
                    {protectedRoutes.map(({ path, element: Component }, index) => {
                        return <Route path={path} element={<Component />} key={index} />
                    })}
                </Routes>
            </Suspense>
        </DashboardLayout>
    )
}

export default PrivateRoutes
