import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { PLAYER_SHIRTS } from './svg/shirts'
import { Button } from 'react-bootstrap'

const TestComponent = () => {
    const masterData = useSelector(state => state.masterData)
    const [selectedShirt, setSelectedShirt] = useState('player')

    const selectPlayers = () => setSelectedShirt('player')
    const selectKeepers = () => setSelectedShirt('keeper')

    //create an image from 0 to a specific length
    return (
        <div>
            <div className="d-flex gap-3 justify-content-center align-items-center" style={{ height: 100 }}>
                <Button variant={selectedShirt === 'player' ? 'success' : 'info'} size="lg" onClick={selectPlayers}>
                    Players
                </Button>
                <Button variant={selectedShirt === 'keeper' ? 'success' : 'info'} size="lg" onClick={selectKeepers}>
                    Keepers
                </Button>
            </div>
            <div className="d-grid grid-cols-4 grid-cols-lg-4 gap-3 justify-content-center align-items-center">
                {Array.from({ length: masterData.shirts[selectedShirt] }, (v, i) => i).map((item, index) => {
                    if(!PLAYER_SHIRTS[`${selectedShirt}_${item + 1}`]) return null
                    return (
                        <div className="d-flex align-items-center position-relative justify-content-center p-4">
                            <img
                                key={index}
                                src={PLAYER_SHIRTS[`${selectedShirt}_${item + 1}`].image}
                                alt={`${selectedShirt}_${item + 1}`}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'helvetica',
                                    fontWeight: 'bold',
                                    fontSize: 40,
                                    color:
                                        PLAYER_SHIRTS[`${selectedShirt}_${item + 1}`].color === 'dark'
                                            ? 'black'
                                            : 'white',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '80%',
                                    marginTop: '-15px',
                                }}>
                                {item + 1}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TestComponent
