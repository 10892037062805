import classNames from 'classnames'
import Avatar from 'components/shared/Avatar'
import { RSVPButton } from 'components/shared/RSVPButton'
import { LoadingWord } from 'components/shared/Skeleton'
import { PLAYER_SHIRTS } from 'components/shared/svg/shirts'
import { cone } from 'components/shared/svg/svgs'
import moment from 'moment'
import { Card } from 'react-bootstrap'
import { ChevronRight } from 'react-feather'
// import moment from 'moment'
import { Link } from 'react-router-dom'
import { getTeamName, isHome, isMatch } from 'utils'

const AVATAR_SIZE = 50
// const DATE_FORMAT = 'ddd MMM Do, YYYY'
const TIME_FORMAT = 'MMM Do'

const getEventLink = event => {
    const { isLoading } = event
    if (isLoading || !event) return {}

    if (isMatch(event)) {
        return {
            search: `?match=${event.squad.squad_slug}|${event.date}`,
        }
    }
    return {
        search: `?training=${event.squad.squad_slug}|${event.id || event.parent_id}|${event.date}`,
    }
}

const MatchEvent = ({ event }) => {
    const { opponent, squad, isLoading } = event
    const eventTimeLocal = moment.utc(event?.start_time).tz(event?.location?.timezone || 'UTC')

    return (
        <Card className="event-card match d-flex flex-column">
            <Card.Header>
                <Card.Title>
                    <p className="d-flex align-items-center text-xs bright-2x fw-semibold my-0 text-center lh-1">
                        <span className="hint-text">
                            {event?.match_type === 'friendly' ? 'Friendly ' : 'League '}Match
                        </span>
                        {/* <ChevronRight className="text-white" size={12} />
                        <span className="hint-text">
                            {getTeamName(squad.name, squad.nickname)} -{' '}
                            {getTeamName(opponent.name, opponent.short_name)}
                        </span> */}
                    </p>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Link
                    to={getEventLink(event)}
                    className={classNames(
                        !isHome(event) ? 'flex-row' : 'flex-row-reverse',
                        'd-flex align-items-center justify-content-center w-100 h-100'
                    )}>
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: 80 }}>
                        <img alt={squad.name} width={AVATAR_SIZE} src={PLAYER_SHIRTS[`player_${event.shirts.player}`]?.image} />
                        <h6 className="fw-semibold text-ellipsis mb-0 text-sm mt-1">
                            {getTeamName(squad.name, squad.nickname)}
                        </h6>
                    </div>
                    <div className="d-flex flex-column align-items-center space-between lh-1 mx-3">
                        <span className="block text-md hint-text">{eventTimeLocal.format('MMM Do')}</span>
                        <span className="block hint-text text-sm lh-1">{eventTimeLocal.format('hh:mm')}</span>
                    </div>
                    <div
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ width: 80, height: 80 }}>
                        <img alt={opponent.name} width={AVATAR_SIZE} src={PLAYER_SHIRTS[`player_${event.opponent.shirts.home}`]?.image} />
                        <h6 className="fw-semibold text-ellipsis mb-0 text-sm mt-1">
                            {getTeamName(opponent.name, opponent.short_name)}
                        </h6>
                    </div>
                </Link>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center space-between gap-2">
                <p className="hint-text text-sm my-0 user-select-none">&nbsp;</p>
                {!isLoading && (event.am_player ? <RSVPButton event={event} /> : <div className="p-1 hint-text">as coach</div>)}
            </Card.Footer>
        </Card>
    )
}

const Event = ({ event }) => {
    const { isLoading } = event

    const eventTimeLocal = moment.utc(event?.start_time).tz(event?.location?.timezone || 'UTC')

    // if (isLoading) return false

    if (!isLoading && isMatch(event)) return <MatchEvent event={event} />

    return (
        <Card className="event-card training">
            <Card.Body>
                <Link to={getEventLink(event)}>
                    <div className="training-head">
                        <Avatar
                            width={AVATAR_SIZE}
                            height={AVATAR_SIZE}
                            boxed
                            src={event.squad.photo_url_sm}
                            alt={event.squad.name}
                        />
                        <div className="d-flex flex-col">
                            <div className="gap-2">
                                <p className="text-sm uppercase my-0 user-select-none">
                                    {isLoading ? <LoadingWord height="sm" big className="mb-1" /> : 'Training'}
                                </p>
                            </div>
                            <div className="event-title user-select-none text-white">
                                {isLoading ? (
                                    <LoadingWord height="lg" className="w-40 mb-1" />
                                ) : (
                                    event?.title || `${event?.module?.label}`
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="training-body">
                    <p className="hint-text text-md mt-2 scouty-regular w-100 line-clamp-3 user-select-none">
                        {event.desc}
                    </p>
                </div>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center space-between gap-2">
                <p className="hint-text text-sm my-0 user-select-none">
                    {eventTimeLocal.format(TIME_FORMAT)} @{eventTimeLocal.format('hh:mm')}
                </p>
                {!isLoading && (event.am_player ? <RSVPButton event={event} /> : <div className="p-1">as coach</div>)}
            </Card.Footer>
        </Card>
    )
}
export default Event
